.routes-max-width {
  @include media-breakpoint-up(lg) {
    max-width: 700px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1680px !important;
    // margin: auto;
  }
}

// Don't hover table when cursor is on trip details 
table.route {
  tr:nth-child(even):hover {
    cursor: initial !important;
    background-color: inherit !important;
  }
}

.leaflet-container {
  min-width: 100% !important;
  min-height: 320px !important;
  
  @include media-breakpoint-up(lg) {
    min-height: 400px !important;
  }
  
  @include media-breakpoint-up(xl) {
    min-height: 500px !important;
  }
}

