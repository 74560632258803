.company-max-width {
  @include media-breakpoint-up(xs) {
    max-width: 400px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(md) {
    max-width: 576px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 677px !important;
    // margin: 0 auto !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1680px !important;
    // margin: 0 auto !important;
  }
}

.company-super-max-width {
  @include media-breakpoint-up(xs) {
    max-width: 400px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(md) {
    max-width: 576px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1000px !important;
    margin: 0 auto !important;
  }
}
