.profile-max-width {
  @include media-breakpoint-between(xl, xxl) {
    width: 888px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(xxl) {
    width: auto !important;
  }
}
