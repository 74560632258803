@import './colors';

@import '../components/marker/styles.scss';

@import '../components/button/styles.scss';

@import '../components/hook-form-field/phone-field.scss';

@import '../containers/TheContent.scss';

@import '../views/company/styles.scss';

@import '../views/discounts/styles.scss';

@import '../components/switch/styles.scss';

@import '../components/discounts-data-table/style.scss';

@import '../views/register/styles.scss';

@import '../views/routes/styles.scss';

@import '../views/profile/styles.scss';

@import '../components/staff/styles.scss';

@import '../views/vehicle/styles.scss';

@import '../views/timetable/styles.scss';

@import '../components/date-single-picker/index.scss';

@import '../containers/TheHeader.scss';

.login-max-width {
  @include media-breakpoint-up(lg) {
    max-width: 1140px !important;
    margin: 0 auto !important;
  }
}

.login-language {
  position: absolute;
  right: 25px;
  top: 0;
}

.font-weight-register {
  font-weight: 600 !important;
}

.selected-flag-primary::before {
  background-color: #321fdb !important;
  z-index: 3;
}

.data-table-container {
  overflow-x: auto;
}

.data-table {
  min-width: 912px;
}

.placeholder-white {
  input::placeholder {
    color: rgba(225, 225, 225, 0.9) !important;
    opacity: 1;
  }
}

.placeholder-dark {
  input::placeholder {
    color: #73818f;
    opacity: 1;
  }
}

.displayView {
  display: flex;
  flexdirection: 'row';
  alignitems: 'center';
}

.parcelTextTrackNumber {
  margintop: 5;
  padding: 0;
}

.parcelText {
  fontfamily: OpenSansSemiBold;
  margin-bottom: 0;
}

.displayHeadingView {
  display: flex;
  flexdirection: 'row';
  justifycontent: 'space-between';
  alignitems: 'center';
  height: 40px;
}

.parcelTextHeader {
  fontfamily: OpenSansBold;
  fontsize: 30;
}
