
.timetable-max-width {
  @include media-breakpoint-up(md) {
    max-width: 768px !important;
  }
}

// Don't hover table when cursor is on trip details 
table.route-trips {
  tr:nth-child(even):hover {
    cursor: initial !important;
    background-color: inherit !important;
  }
}