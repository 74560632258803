@import "../../scss/_colors.scss";

.vehicle-max-width {
  @include media-breakpoint-between(xl, xxl) {
    max-width: 888px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1680px !important;
  }
}

.seat-grid {
  display: flex;
  justify-content: space-between;
  height: auto;
}

.seat {
  display: flex;
  flex-direction: column ;
  background: $lightGrey;
}