.price-new {
  font-size: 20px;
}

.price-line-through {
  background-color: transparent;
  background-image: gradient(
    linear,
    19.1% -7.9%,
    81% 107.9%,
    color-stop(0, transparent),
    color-stop(0.48, transparent),
    color-stop(0.5, #000),
    color-stop(0.52, transparent),
    color-stop(1, transparent)
  );
  background-image: repeating-linear-gradient(
    172deg,
    transparent 0%,
    transparent 48%,
    rgb(255, 255, 255) 50%,
    transparent 55%,
    transparent 100%
  );
}
.price-red-line-through {
  background-color: transparent;
  background-image: gradient(
    linear,
    19.1% -7.9%,
    81% 107.9%,
    color-stop(0, transparent),
    color-stop(0.48, transparent),
    color-stop(0.5, #000),
    color-stop(0.52, transparent),
    color-stop(1, transparent)
  );
  background-image: repeating-linear-gradient(
    172deg,
    transparent 0%,
    transparent 48%,
    rgb(255, 0, 0) 50%,
    transparent 55%,
    transparent 100%
  );
}
