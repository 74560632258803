.discounts-max-width {
  @include media-breakpoint-up(xxl) {
    max-width: 1124px !important;
    margin: 0 auto !important;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1680px !important;
  }
}

.remove-button {
  cursor: pointer;
}

.remove-button:active {
  color: #862020 !important;
}

// Don't hover table when cursor is on trip details
table.discount-trips {
  tr {
    cursor: initial !important;
    background-color: inherit !important;
  }
}

.trip-width {
  @media (min-width: 534px) {
    max-width: 240px;
    margin-right: 1rem;
  }

  @include media-breakpoint-up(sm) {
    max-width: 320px;
    min-width: 260px;
  }
}
