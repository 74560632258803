$grey: rgb(204, 204, 204);
$greyDarkish: rgb(170, 170, 170);
$greyishBrown: rgb(68, 68, 68);
$greyishBrownTransparent: rgba(68, 68, 68, 0.5);
$greyishWhite: rgb(230, 230, 230);
$lighterGrey: rgb(240, 240, 240);
$lightGrey: rgb(246, 246, 246);
$darkGrey: rgb(110, 110, 110);
$darkerGrey: rgb(57,58,66);
$red: rgb(204, 0, 0);
$redPrimary: rgb(236, 0, 0);
$redDark: rgb(170, 13, 13);
$shadow: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$warmGrey: rgb(118, 118, 118);
$white: rgb(256, 256, 256);
$borderWhite: rgba(255, 255, 255, 0.75);
$borderBlack: rgba(0, 0, 0, 0.2);
$borderColor: rgb(155, 195, 211);
$black: rgb(0, 0, 0);
$blackTranslucent: rgba(0, 0, 0, 1);
$green: rgb(0, 132, 41);
$brown: rgb(68, 42, 29);
$brownLight: rgb(171, 137, 137);
$brownPrimary: rgb(195, 120, 82);
$darkBlue: rgb(26, 37, 77);
$dimmerBlue: rgb(46, 48, 92);
$lightBlue: rgb(59, 151, 211);
$lighterBlue: rgb(66, 136, 214);
$purple: rgb(68, 57, 176);
$yellow: rgb(255,255,0);
