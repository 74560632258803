// .stop-icon-line {
//   height: 15px;
//   width: 2.5px;
//   background-color: black;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;
//   align-self: center;
// }

.stop-icon-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 25px;
}

.stop-icon-container {
  display: flex;
  flex-flow: column nowrap;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: white;
  margin-left: -12px;
  margin-top: -8px;
}

.vehicle-tracker-container {
  display: flex;
  flex-flow: column nowrap;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  //background-color: white;
  margin-left: -12px;
  margin-top: -8px;
}
